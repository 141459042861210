import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import {Layout, Breadcrumb, theme, ConfigProvider, Card} from 'antd';
import MainHeader from "./tsx/Main/Header/Header";
import MainFooter from "./tsx/Main/Footer/Footer";
import MainAbout from "./tsx/Main/About/About";
import MainCopyright from "./tsx/Main/Copyright/About";
import MainAI from "./tsx/Main/AI/MainAI";
import Friends from "./tsx/Main/Friends/Friends";
const { Content} = Layout;





const Main: React.FC = () => {
  theme.useToken();
    return (
        <div className="App">
            <Layout>
                <ConfigProvider   theme={{
                    algorithm: theme.darkAlgorithm,
                    components: {
                        Layout: {
                        },
                    },
                }}>
            <Layout style={{ display: 'flex',minHeight: '95vh' }}>
                <MainHeader/>
                <Content style={{padding: '0 48px', flexGrow: 1}}> {/* flexGrow: 1 使 Content 占据剩余空间 */}
                    <Breadcrumb style={{margin: '16px 0'}}>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                    </Breadcrumb>
                    <h1>欢迎来到PBlaze</h1>
                    <h2>欢迎访问以下服务</h2>
                    <Card style={{ textAlign: 'center', padding: '15px' }}>
                        <h1>
                            <a href='https://blog.pblaze.com' style={{ textDecoration: 'none' }}>
                                博客
                            </a>
                        </h1>
                    </Card>

                </Content>
            </Layout>
                    <MainFooter/>
                </ConfigProvider>
            </Layout>
        </div>
    );
};

function App() {
    return (
        <BrowserRouter>
            <div className="container">
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/ai" element={<MainAI />} />
                    <Route path="/about" element={<MainAbout />} />
                    <Route path="/copyright" element={<MainCopyright />} />
                    <Route path="/friends" element={<Friends />} />
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
import React from 'react';
import { Layout, Menu} from "antd";
const { Header } = Layout;

function MainHeader() {
    const items = [
        {
            label: '主页',
            key: '1',
            onClick: () => { window.location.href = '/'; }
        },
        {
            label: 'AI对话',
            key: '2',
            onClick: () => { window.location.href = '/ai'; }
        },
        {
            label: '关于我们',
            key: '3',
            onClick: () => { window.location.href = '/about'; }
        },
        {
            label: '版权信息',
            key: '4',
            onClick: () => { window.location.href = '/copyright'; }
        },
        {
            label: '友情链接',
            key: '5',
            onClick: () => { window.location.href = '/friends'; }
        },

    ];
    return (
        <div>
            <Layout>
                <Header style={{display: 'flex', alignItems: 'center'}}>
                    <h1 style={{ color: 'white' }}>
                        PBlaze
                    </h1>
                    <div className="pblaze-logo"/>
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={['']}
                        items={items}
                        style={{flex: 1, minWidth: 0}}
                    />
                </Header>
            </Layout>
        </div>
    )
}
export default MainHeader;
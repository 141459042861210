import React from 'react';
import {Breadcrumb, Col, ConfigProvider, Layout, Row, theme} from "antd";
import MainHeader from "../Header/Header";
import {Content} from "antd/es/layout/layout";
import MainFooter from "../Footer/Footer";


const Friends = () => {
    const friendLinks = [
        { name: '🧐 无限折腾', url: 'https://iamin.icu' , description:'iamin.icu'}
    ];

    return (
        <Layout>
            <ConfigProvider   theme={{
                algorithm: theme.darkAlgorithm,
                components: {
                    Layout: {
                    },
                },
            }}>
                <MainHeader />
                <Layout style={{flex: 1, minHeight: "90vh"}}>
                    <Content style={{padding: '0 48px', flexGrow: 1}}>
                        <Breadcrumb style={{margin: '16px 0'}}>
                            <Breadcrumb.Item>友情链接</Breadcrumb.Item>
                        </Breadcrumb>
                        <Row gutter={[16, 16]}>
                            {friendLinks.map((link, index) => (
                                <Col xs={24} sm={12} md={8} key={index}>
                                    <Content style={{ padding: '20px', background: '#fff', fontSize: '36px', borderRadius: '10px' }}>
                                        <a href={link.url} target="_blank" rel="noopener noreferrer">
                                            {link.name} {link.description}
                                        </a>
                                    </Content>
                                </Col>
                            ))}
                        </Row>
                    </Content>
                </Layout>
                <MainFooter/>
            </ConfigProvider>
        </Layout>
    )
}

export default Friends;